import styled from "@emotion/styled";
import React, { useContext, Fragment, useState } from "react";

import logo from "../../assets/img/landing/logoTest.svg";
// import iconLogo from "../../assets/img/iconLogo.svg";
import BuyIcon from "../../assets/icons/Buy.svg";
import Histogram from "../../assets/icons/Histogram.svg";
import Ticket from "../../assets/icons/Ticket.svg";
import Logout from "../../assets/icons/Logout.svg";
import Document from "../../assets/icons/Document.svg";
import Setting from "../../assets/icons/Setting.svg";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import Multiselect from "../../assets/icons/Multiselect.svg";
import BookLogic from "../../assets/icons/BookLogic.svg";
import ChartLine from "../../assets/icons/ChartLine.svg";
import { Body2 } from "../../layouts/TextStyles";
import GroupIcon from "@material-ui/icons/Group";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import {
  ChevronLeft as ChevronLeftIcon,
  //   ViewHeadline,
     Dashboard,
  //   LocalLibrary,
  //   MonetizationOn,
  //   SupervisorAccount,
  AssignmentTurnedIn,
  Assignment,
  Help,
  //   Settings,
  GroupWork,
  LocalOffer,
  AccountCircle,
  SupervisedUserCircle,
} from "@material-ui/icons";
import AssessmentIcon from "@material-ui/icons/Assessment";
import RateReviewIcon from '@material-ui/icons/RateReview';
import LocationOnIcon from "@material-ui/icons/LocationOn";
// import logo from "../../assets/img/logo.png";
import logo2 from "../../assets/img/iconLogo.svg";
import { AppContext } from "../../context/Context";

import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { useHistory, Link as RouterLink, useLocation } from "react-router-dom";
import storage from "../../services/storageService";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
// import { makeStyles } from "@material-ui/core/styles";
// import {
//   Drawer,
//   IconButton,
//   Divider,
//   List,
//   ListItemText,
//   ListItemIcon,
//   ListItem,
// } from "@material-ui/core";

const menuLinks = [
  {
    id: 0,
    title: <FormattedMessage id="dashboard" />,
    icon: Dashboard,
    link: "/dashboard",
    authLevel: ["CUSTOMER"],
  },
  {
    id: 10,
    title: <FormattedMessage id="staff" />,
    icon: AccountCircle,
    authLevel: ["SUPER_ADMIN"],
    subMenuLinks: [
      {
        id: 11,
        title: <FormattedMessage id="moderator" />,
        icon: AssignmentTurnedIn,
        link: "/moderators",
        authLevel: ["SUPER_ADMIN"],
      },
      // {
      //   id: 12,
      //   title: <FormattedMessage id="salesman" />,
      //   icon: MonetizationOn,
      //   link: "/salesman",
      //   authLevel: ["SUPER_ADMIN"],
      // },
      {
        id: 13,
        title: <FormattedMessage id="expert" />,
        icon: GroupWork,
        link: "/experts",
        authLevel: ["SUPER_ADMIN"],
      },
      {
        id: 14,
        title: <FormattedMessage id="editor" />,
        icon: Assignment,
        link: "/editors",
        authLevel: ["SUPER_ADMIN"],
      },
    ],
  },
  {
    id: 15,
    title: <FormattedMessage id="staff" />,
    icon: AccountCircle,
    authLevel: ["MODERATOR"],
    subMenuLinks: [
      {
        id: 16,
        title: <FormattedMessage id="expert" />,
        icon: GroupWork,
        link: "/experts",
        authLevel: ["SUPER_ADMIN"],
      },
      {
        id: 17,
        title: <FormattedMessage id="editor" />,
        icon: Assignment,
        link: "/editors",
        authLevel: ["MODERATOR"],
      },
    ],
  },
  // {
  //   id: 20,
  //   title: <FormattedMessage id="customers" />,
  //   icon: SupervisedUserCircle,
  //   authLevel: ["SUPER_ADMIN"],
  //   subMenuLinks: [
  //     {
  //       id: 21,
  //       title: <FormattedMessage id="schoolAdmin" />,
  //       icon: SupervisorAccount,
  //       link: "/school-admin",
  //       authLevel: ["SUPER_ADMIN"],
  //     },
  //     {
  //       id: 22,
  //       title: <FormattedMessage id="teachers" />,
  //       link: "/teachers",
  //       icon: Assignment,
  //       authLevel: ["SUPER_ADMIN"],
  //     },
  //     {
  //       id: 23,
  //       title: <FormattedMessage id="students" />,
  //       link: "/students",
  //       icon: GroupWork,
  //       authLevel: ["SUPER_ADMIN"],
  //     },
  //   ],
  // },
  {
    id: 3,
    title: <FormattedMessage id="tariff" />,
    icon: LocalOffer,
    link: "/tariffs-discounts",
    authLevel: ["MODERATOR"],
  },
  // {
  //   id: 4,
  //   title: <FormattedMessage id="tariffPackage" />,
  //   icon: LocalAtmIcon,
  //   link: "/tariffs-package",
  //   authLevel: ["MODERATOR"],
  // },
  // {
  //   id: 4,
  //   title: <FormattedMessage id="users" />,
  //   icon: LocalLibrary,
  //   authLevel: ["SCHOOL_ADMIN"],
  //   subMenuLinks: [
  //     {
  //       id: 41,
  //       title: <FormattedMessage id="teachers" />,
  //       link: "/teachers",
  //       icon: Assignment,
  //       authLevel: ["SCHOOL_ADMIN"],
  //     },
  //     {
  //       id: 42,
  //       title: <FormattedMessage id="students" />,
  //       link: "/students",
  //       icon: AccountCircle,
  //       authLevel: ["SCHOOL_ADMIN"],
  //     },
  //     {
  //       id: 43,
  //       title: <FormattedMessage id="pendingStudents" />,
  //       link: "/students/pending",
  //       icon: SupervisedUserCircle,
  //       authLevel: ["SCHOOL_ADMIN"],
  //     },
  //   ],
  // },

  {
    id: 5,
    title: <FormattedMessage id="topicSetup" />,
    icon: Assignment,
    link: "/topics",
    authLevel: ["EXPERT"],
  },
  {
    id: 18,
    title: <FormattedMessage id="subjects" />,
    icon: Assignment,
    link: "/subjects",
    authLevel: ["MODERATOR"],
  },
  {
    id: 19,
    title: <FormattedMessage id="questions" />,
    icon: Help,
    link: "/questions-count",
    authLevel: ["MODERATOR"],
  },
  {
    id: 20,
    title: <FormattedMessage id="region" />,
    icon: LocationOnIcon,
    link: "/location",
    authLevel: ["MODERATOR"],
  },
  {
    id: 21,
    title: <FormattedMessage id="students" />,
    icon: GroupIcon,
    link: "/studentsList",
    authLevel: ["MODERATOR", "SALESMAN", "CUSTOMER"],
  },
  {
    id: 22,
    title: <FormattedMessage id="customers" />,
    icon: Assignment,
    link: "/customers",
    authLevel: ["MODERATOR", "SALESMAN"],
  },
  {
    id: 23,
    title: <FormattedMessage id="salesman" />,
    icon: ContactPhoneIcon,
    link: "/salesman",
    authLevel: ["MODERATOR"],
  },
  {
    id: 111,
    title: <FormattedMessage id="salesReport" />,
    icon: MonetizationOnIcon,
    link: "/sales-report",
    authLevel: ["MODERATOR"],
  },
  {
    id: 6,
    title: <FormattedMessage id="contexts" />,
    nIcon: Document,
    link: "/paragraphs",
    authLevel: ["EDITOR", "EXPERT"],
  },
  {
    id: 7,
    title: <FormattedMessage id="questions" />,
    icon: Help,
    authLevel: ["EDITOR", "EXPERT"],
    subMenuLinks: [
      {
        id: 71,
        title: <FormattedMessage id="allQuestions" />,
        // icon: Help,
        nIcon: BookLogic,
        link: "/questions",
        authLevel: ["EDITOR", "EXPERT"],
      },
      {
        id: 72,
        title: <FormattedMessage id="pendingQuestions" />,
        nIcon: Multiselect,
        link: "/questions/pending",
        authLevel: ["EDITOR", "EXPERT"],
      },
    ],
  },
  {
    id: 105,
    title: <FormattedMessage id="examGroups" />,
    icon: ViewAgendaIcon,
    link: "/exam-group",
    authLevel: ["MODERATOR"],
  },
  {
    id: 8,
    title: <FormattedMessage id="examTypeSetup" />,
    icon: AssignmentTurnedIn,
    link: "/exam-type",
    authLevel: ["MODERATOR"],
  },
  {
    id: 9,
    title: <FormattedMessage id="examSetup" />,
    icon: GroupWork,
    link: "/exam-setup",
    authLevel: ["MODERATOR", "SALESMAN"],
  },
  {
    id: 100,
    title: <FormattedMessage id="exams" />,
    icon: Assignment,
    //link: "/student",
    authLevel: ["STUDENT"],
    subMenuLinks: [
      {
        id: 0,
        title: <FormattedMessage id="myExams" />,
        nIcon: Document,
        link: "/pending-exams",
        authLevel: ["ALL"],
      },
      // {
      //   id: 105,
      // title: <FormattedMessage id="passExam" />,

      //   title: <FormattedMessage id="enterCode" />,
      //   nIcon: Ticket,
      //   link: "/pass-exam",
      //   authLevel: ["STUDENT"],
      // },
      {
        id: 101,
        // title: <FormattedMessage id="completedExams" />,
        nIcon: Histogram,
        title: <FormattedMessage id="results" />,
        link: "/completed-exams",
        authLevel: ["STUDENT"],
      },
      {
        id: 104,
        nIcon: BuyIcon,
        title: <FormattedMessage id="purchaseExams" />,
        link: "/purchase-exams",
        authLevel: ["STUDENT"],
      },
      /*
      {
        id: 101,
        title: <FormattedMessage id="completedExams" />,
        link: "/completed-exams",
        authLevel: ["STUDENT"],
      },
      {
        id: 102,
        title: <FormattedMessage id="pendingExams" />,
        link: "/pending-exams",
        authLevel: ["STUDENT"],
      },
      {
        id: 103,
        title: <FormattedMessage id="processingExams" />,
        link: "/processing-exams",
        authLevel: ["STUDENT"],
      },
    ,*/
    ],
  },
  {
    id: 30,
    title: <FormattedMessage id="examAnalysis" />,
    nIcon: ChartLine,
    link: "/exam-analysis",
    authLevel: ["MODERATOR", "CUSTOMER"],
  },
  {
    id: 34,
    title: "Excel анализ",
    icon: AssessmentIcon,
    link: "/excel-analysis",
    authLevel: ["MODERATOR"],
  },
  {
    id: 35,
    title: "Аппеляция",
    icon: QuestionAnswerIcon,
    link: "/appeal-questions",
    authLevel: ["MODERATOR", "EXPERT"],
  },
  {
    id: 27,
    title: <FormattedMessage id="contracts" />,
    icon: ImportContactsIcon,
    link: "/contracts",
    authLevel: ["MODERATOR"],
  },
  {
    id: 29,
    title: <FormattedMessage id="rating" />,
    icon: RateReviewIcon,
    link: "/review",
    authLevel: ["MODERATOR"],
  },
  {
    id: 36,
    title: <FormattedMessage id="subscription" />,
    icon: BeenhereIcon,
    link: "/subscriptions",
    authLevel: ["MODERATOR"],
  },
  {
    id: 33,
    title: <FormattedMessage id="landingExams" />,
    icon: DesktopWindowsIcon,
    link: "/landing-exams",
    authLevel: ["MODERATOR"],
  },
  {
    id: 31,
    title: <FormattedMessage id="university" />,
    icon: SchoolIcon,
    link: "/university",
    authLevel: ["MODERATOR"],
  },
  {
    id: 32,
    title: <FormattedMessage id="speciality" />,
    icon: WorkIcon,
    link: "/speciality-group",
    authLevel: ["MODERATOR"],
  },
  {
    id: 110,
    title: <FormattedMessage id="settings" />,
    nIcon: Setting,
    link: "/settings/personal-info",
    authLevel: ["ALL"],
  },
];

function Sidebar({ isMenu, setIsMenu, handleDrawerClose }) {
  const history = useHistory();

  const { user, setUser } = useContext(AppContext);

  const handleLogout = () => {
    history.push("/");

    setUser(null);
    storage?.remove("refreshToken");
    storage?.remove("token");
    storage?.remove("user");
    storage?.remove("profile");
  };

  const menuLinkComp = (item, isSub) => {
    return (
      <Fragment key={item.id}>
        {/* <ListItem
          button
          selected={item.link === pathname}
          component={RouterLink}
          to={item.link}
          onClick={handleShowSubMenu(item?.subMenuLinks?.length, item.id)}
          style={{ padding: "8px" }}
        >
          <div style={{ marginLeft: isSub ? "2rem" : "" }}>
            <ListItemIcon style={{ minWidth: isSub ? "28px" : "40px" }}>
              {item.icon && <item.icon />}
            </ListItemIcon>
          </div>
          <ListItemText primary={item.title} />
        </ListItem> */}
        {item?.subMenuLinks?.length ? (
          <CategoryTitle
            style={{
              display: isMenu ? "flex" : "none",
            }}
          >
            {item.title}
          </CategoryTitle>
        ) : (
          <RouterLink to={item.link}>
            <MobileMenuItemDesk>
              <div style={{ width: 24, height: 24 }}>
                {item.icon && <item.icon />}
                {item.nIcon && <img src={item.nIcon} />}
              </div>
              <Body2>{item.title}</Body2>
            </MobileMenuItemDesk>
            <MobileMenuItemM onClick={handleDrawerClose}>
              <div style={{ width: 24, height: 24 }}>
                {item.icon && <item.icon />}
                {item.nIcon && <img src={item.nIcon} />}
              </div>
              <Body2>{item.title}</Body2>
            </MobileMenuItemM>
          </RouterLink>
        )}
        {item?.subMenuLinks?.length &&
          // set sublinks always open
          // openMenuList.includes(item.id)  &&
          item.subMenuLinks.map((el) => menuLinkComp(el, true))}
        {item?.subMenuLinks?.length && <DividerH />}
      </Fragment>
    );
  };

  // const handleShowSubMenu = (length, id) => () => {
  //   if (length) {
  //     if (openMenuList.includes(id)) {
  //       setOpenMenuList(openMenuList.filter((i) => i !== id));
  //     } else setOpenMenuList([...openMenuList, id]);
  //   }
  // };
  return (
    <div>
      <Wrapper
        isOpen={isMenu}
        // onMouseOver={() => setIsMenu(true)}
        // onMouseLeave={() => setIsMenu(false)}
      >
        <SidebarWrapper>
          <div style={{ width: isMenu ? "100%" : "30px", overflow: "hidden" }}>
            <img
              style={{
                margin: "20px 8px 4px",
                height: 26,
                width: 140,
              }}
              // src={isMenu ? logo : iconLogo}
              src={logo}
            />
          </div>
          <DividerH />

          {menuLinks.map((item, index) => {
            let isUserAuthMenuLevel = false;
            if (item?.authLevel?.includes("ALL")) isUserAuthMenuLevel = true;
            else
              item?.authLevel?.forEach((level) => {
                if (user?.roles?.includes(level)) isUserAuthMenuLevel = true;
              });
            return isUserAuthMenuLevel ? menuLinkComp(item) : null;
          })}

          <MobileMenuItem onClick={() => handleLogout()}>
            <div style={{ width: 24 }}>
              <img src={Logout} />
            </div>
            <Body2
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <FormattedMessage
                id="sign_out"
                style={{ whiteSpace: "nowrap" }}
              />
            </Body2>
          </MobileMenuItem>
        </SidebarWrapper>
      </Wrapper>
    </div>
  );
}

export default Sidebar;

const Wrapper = styled.div`
  background: white;
  /* box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.25),
    inset 0px 0px 0px 0.5px rgba(255, 255, 255, 0.2); */
  box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.12);
  /* backdrop-filter: blur(40px); */
  /* padding: 0px 8px; */
  /* position: fixed; */
  top: 0px;
  left: 0px;
  width: 230px;
  width: ${(props) => (props.isOpen ? "230px" : "40px")};
  height: 100%;
  /* position: fixed; */
  /* cursor: pointer; */
  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;

  /* transform: ${(props) =>
    props.isOpen
      ? "skewY(0deg) rotate(0deg) translateX(0px) translateY(0px)"
      : "skewY(0deg) rotate(0deg) translateX(-240px) translateY(0px)"}; */

  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 600px) {
    position: fixed;
    z-index: 12;
    transform: ${(props) =>
      props.isOpen
        ? "skewY(0deg) rotate(0deg) translateX(0px) translateY(0px)"
        : "skewY(0deg) rotate(0deg) translateX(-240px) translateY(0px)"};
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
    color: #1d1e1c;
  }
`;

const SidebarWrapper = styled.div`
  display: grid;
  gap: 8px;
  /* grid-template-columns: 150px; */
  /* grid-template-columns: 170px; */
  /* margin-top: 16px; */
`;

const MobileMenuItem = styled.div`
  /* color: rgba(255, 255, 255, 0.7); */
  color: black;
  display: flex;
  /* grid-template-columns: auto; */
  gap: 12px;
  align-items: center;
  padding: 12px 8px;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  overflow: hidden;
  white-space: nowrap;
  /* border-radius: 8px; */
  /* justify-content: start; */
  cursor: pointer;
  img {
    height: 24px;
  }

  :hover {
    /* border-bottom: 2px solid rgba(0, 0, 0, 0.7); */
    background: rgba(51, 102, 255, 0.07);
    background: #f4f5f6;
  }
  transition: 0.2s ease-out;
`;

const DividerH = styled.div`
  height: 2px;
  width: 100%;
  background: rgba(237, 237, 237, 1);
`;

const CategoryTitle = styled.div`
  font-weight: 500;
  text-transform: uppercase;
  color: grey;
  font-size: 12px;
  padding: 8px;
`;

const Backdrop = styled.div`
  visibility: ${(props) => (props.isMenu ? "visible" : "hidden")};
  opacity: ${(props) => (props.isMenu ? 1 : 0)};
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    256.42deg,
    #161c24 0%,
    rgba(22, 28, 36, 0.48) 91.29%
  );
  transition: all 0.2s ease-in-out;

  /* @media screen and (min-width: 600px) {
    display: none;
  } */
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const MobileMenuItemDesk = styled.div`
  /* color: rgba(255, 255, 255, 0.7); */
  color: black;
  display: flex;
  /* grid-template-columns: auto; */
  gap: 12px;
  align-items: center;
  padding: 12px 8px;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  overflow: hidden;
  white-space: nowrap;
  /* border-radius: 8px; */
  /* justify-content: start; */
  cursor: pointer;
  img {
    height: 24px;
  }

  :hover {
    /* border-bottom: 2px solid rgba(0, 0, 0, 0.7); */
    background: rgba(51, 102, 255, 0.07);
    background: #f4f5f6;
  }
  transition: 0.2s ease-out;

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const MobileMenuItemM = styled.div`
  /* color: rgba(255, 255, 255, 0.7); */
  color: black;
  display: flex;
  /* grid-template-columns: auto; */
  gap: 12px;
  align-items: center;
  padding: 12px 8px;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  overflow: hidden;
  white-space: nowrap;
  /* border-radius: 8px; */
  /* justify-content: start; */
  cursor: pointer;
  img {
    height: 24px;
  }

  :hover {
    /* border-bottom: 2px solid rgba(0, 0, 0, 0.7); */
    background: rgba(51, 102, 255, 0.07);
    background: #f4f5f6;
  }
  transition: 0.2s ease-out;

  @media screen and (min-width: 501px) {
    display: none;
  }
`;
